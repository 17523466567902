import React, { useState, useEffect } from 'react';
import { Label, Form, FormGroup, Input,ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Modal, Tab, Nav, Button } from 'react-bootstrap';
import InputMask from 'react-input-mask'; 
import { FaEye, FaEyeSlash } from 'react-icons/fa'; 
import { useNavigate } from 'react-router-dom';
import { FaExclamationCircle } from 'react-icons/fa';

import { Checkmark } from 'react-checkmark'
import style from "./plataforma.module.css";
import axios from 'axios';

const UserConfig = () => {
  const navigate = useNavigate();


  const [toggleState, setToggleState] = useState(1);
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [isCpfInvalid, setIsCpfInvalid] = useState(false);
  const [isCnpjInvalid, setIsCnpjInvalid] = useState(false);
  const [isCepInvalid, setIsCepInvalid] = useState(false);
  const [isTelefoneInvalid, setIsTelefoneInvalid] = useState(false);
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible,setConfirmPasswordVisible] = useState(false);
  const [isPasswordVisible1, setPasswordVisible1] = useState(false);
  const [isPasswordVisible2, setPasswordVisible2] = useState(false);
  const [isPasswordVisible3, setPasswordVisible3] = useState(false);
  const [isDadosInvalid, setDadosInvalid] = useState(false);
  
  
  const [modal_C2, setModal_C2] = useState(false);
  //const toggle_C2 = () => setModal_C2(!modal_C2);

  const toggle_C2 = () => {
    setModal_C2(!modal_C2);
    setToggleState(1);
  };

  const [modal_C3, setModal_C3] = useState(false);
  //const toggle_C2 = () => setModal_C2(!modal_C2);

  const toggle_C3 = () => {
    setModal_C3(!modal_C3);

    setModal_C3(!modal_C3);
    if (modal_C3) {
      window.location.reload();
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(prev => !prev);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(prev => !prev);
  };

  const togglePasswordVisibility1 = () => {
    setPasswordVisible1(prev => !prev);
  };

  const togglePasswordVisibility2 = () => {
    setPasswordVisible2(prev => !prev);
  };

  const togglePasswordVisibility3 = () => {
    setPasswordVisible3(prev => !prev);
  };

  const [modal_C, setModal_C] = useState(false);
  const toggle_C = () => setModal_C(!modal_C);

  const [modal_S, setModal_S] = useState(false);
  
  
  const toggle_S =(event) => {
    event.preventDefault();

    setModal_S(!modal_S);
  }

  const EmpresaInfo = JSON.parse(sessionStorage.getItem('EmpresaInfo'));
  const [formData, setFormData] = useState({
    idEmpresa: EmpresaInfo.idEmpresa,
    Nome_Empresa: EmpresaInfo.Nome_Empresa,
    Email_Empresa : EmpresaInfo.Email_Empresa,
    Telefone_Empresa : EmpresaInfo.Telefone_Empresa,
    CEP_Empresa: EmpresaInfo.CEP_Empresa,
    Numero : EmpresaInfo.Numero,
    Complemento_Empresa : EmpresaInfo.Complemento_Empresa,
    Representante_Empresa : EmpresaInfo.Representante_Empresa,
    CPF_Representante: EmpresaInfo.CPF_Representante,
    CNPJ: EmpresaInfo.CNPJ
    });

    const [errors, setErrors] = useState({
      Nome_Empresa: '',
      Email_Empresa : '',
      Telefone_Empresa : '',
      CEP_Empresa: '',
      Numero : '',
      Complemento_Empresa : '',
      Representante_Empresa : '',
      CPF_Representante: '',
      CNPJ: '',
      uf: '',
      Numero: ''
      });

      const ProximaPagina = (event) => {
        event.preventDefault();
        if (toggleState === 1){
          setToggleState((2));
        }else if (toggleState === 2){
          setToggleState((3));
        }
      }

    const [Localizacao, setLocalizacao] = useState({
      bairro: '',
      cep: '',
      complemento: '',
      ddd: '',
      gia: '',
      ibge: '',
      localidade: '',
      logradouro: '',
      siafi: '',
      uf: ''
    });

    const [Senhas, setSenhas] = useState({
      Senha_atual: '',
      Senha_nova: '',
      Senha_c_nova: ''
    });

    const [Senhas_Del, setSenhas_Del] = useState({
      Senha_Del: '',
      Senha_Del2: ''
      });

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    var cep_limpo = value.replace('-', '').replace(/_/g, '');

    if (name === "CEP_Empresa" && cep_limpo.length === 8) {

      axios.post('https://linkestagio.com.br/App.php?operation=get-localizacao', { CEP: value }, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then(response => {
        if (!('erro' in response.data)) {
          setIsCepInvalid(false);
          errors['CEP_Empresa'] = false;

          setLocalizacao({
            bairro: response.data.bairro,
            cep: response.data.cep,
            complemento: response.data.complemento,
            ddd: response.data.ddd,
            gia: response.data.gia,
            ibge: response.data.ibge,
            localidade: response.data.localidade,
            logradouro: response.data.logradouro,
            siafi: response.data.siafi,
            uf: response.data.uf
          });

        }else{
        errors['CEP_Empresa'] = true;
        setIsCepInvalid(true);
        setLocalizacao({
          bairro: '',
          cep: '',
          complemento: '',
          ddd:'',
          gia: '',
          ibge: '',
          localidade: '',
          logradouro: '',
          siafi: '',
          uf: ''
        });
      }

      })
      .catch(error => {
        console.error('Erro:', error);
      });

    }

    setFormData({ ...formData, [name]: value });
  };

  const handleSenhaChange = (event) => {
    const { name, value } = event.target;
    setSenhas({ ...Senhas, [name]: value });
};

  function validatePassword(password, confirmPassword) {
    const hasMinLength = password.length >= 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /[0-9]/.test(password);
    const isMatch = password === confirmPassword;

    return hasMinLength && hasUpperCase && hasLowerCase && hasNumbers && isMatch;
  }

  function validaCNPJ(cnpj) {

    cnpj = (cnpj ?? '').replace(/[^\d]+/g, ''); // Remove caracteres não numéricos
    if (cnpj.length !== 14) return false; // Verifica o tamanho
    if (cnpj == "00000000000000" || 
    cnpj == "11111111111111" || 
    cnpj == "22222222222222" || 
    cnpj == "33333333333333" || 
    cnpj == "44444444444444" || 
    cnpj == "55555555555555" || 
    cnpj == "66666666666666" || 
    cnpj == "77777777777777" || 
    cnpj == "88888888888888" || 
    cnpj == "99999999999999")
    return false;
  
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    const digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== parseInt(digitos.charAt(0))) return false;
  
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== parseInt(digitos.charAt(1))) return false;
  
    return true;
  }

  function validaCPF(cpf) {
    cpf = (cpf ?? '').replace(/[^\d]+/g, ''); // Remove caracteres não numéricos
    if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false; // Verifica o tamanho e sequências iguais

    let soma = 0;
    for (let i = 0; i < 9; i++) soma += parseInt(cpf.charAt(i)) * (10 - i);
    let resto = 11 - (soma % 11);
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.charAt(9))) return false;

    soma = 0;
    for (let i = 0; i < 10; i++) soma += parseInt(cpf.charAt(i)) * (11 - i);
    resto = 11 - (soma % 11);
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.charAt(10))) return false;

    return true;
  }

  function validaTelefone(telefone) {
    const regex = /^\(\d{2}\) \d{8,9}$/;
    return regex.test(telefone);
  }


  useEffect(() => {
    axios.post('https://linkestagio.com.br/App.php?operation=get-localizacao', { CEP: EmpresaInfo.CEP_Empresa }, {
      headers: { 'Content-Type': 'application/json' }
    })
    .then(response => {

      setLocalizacao({
        bairro: response.data.bairro,
        cep: response.data.cep,
        complemento: response.data.complemento,
        ddd: response.data.ddd,
        gia: response.data.gia,
        ibge: response.data.ibge,
        localidade: response.data.localidade,
        logradouro: response.data.logradouro,
        siafi: response.data.siafi,
        uf: response.data.uf
      });

    })
    .catch(error => {
      console.error('Erro:', error);
    });
  }, [EmpresaInfo.CEP_Empresa]);

  const Alterar_senha = (event) => {
    event.preventDefault();
    var Erro = 0;

    if (validatePassword(Senhas['Senha_nova'], Senhas['Senha_c_nova']))  {
      setIsPasswordInvalid(false);
    } else {
      setIsPasswordInvalid(true);
      Erro += 1;
    }    

    if (Erro != 0){
      return;

    }else{

      const combinedData = {
        ...Senhas,
        Email_Empresa: formData['Email_Empresa']
      };

      axios.post('https://linkestagio.com.br/App.php?operation=editar-senha-empresa', combinedData)
        .then((response) => {

          if(response.data == 'Senha Errada'){
            setIsPasswordInvalid(true);

          }else{
            toggle_C();
            Senhas['Senha_atual'] = '';
            Senhas['Senha_nova'] = '';
            Senhas['Senha_c_nova'] = '';

          }

        })
        .catch((error) => {
          console.error('Erro de login:', error);
        });

    }

  }

  const Apagar_Conta = (event) => {
    event.preventDefault();
    var Erro = 0;


    if (validatePassword(Senhas_Del['Senha_Del'], Senhas_Del['Senha_Del2']))  {
      setIsPasswordInvalid(false);
    } else {
      setIsPasswordInvalid(true);
      Erro += 1;
    }    
    if (Erro != 0){
      return;

    }else{

      const combinedData = {
        ...Senhas_Del,
        Email_Empresa: formData['Email_Empresa']
      };

      axios.post('https://linkestagio.com.br/App.php?operation=excluir-empresa', combinedData)
        .then((response) => {
          if (response.data != "Relações de vagas com estudantes, vagas e empresa apagadas com sucesso."){
            navigate('/'); 
          }
        })
        .catch((error) => {
          console.error('Erro de login:', error);
        });

    }

  }

  const handleSubmit = (event) => {
    event.preventDefault();
    var Erro = 0;

    if (!validaCPF(formData['CPF_Representante'])) {
        errors['CPF_Representante'] = true;
        //setDadosInvalid(true);
        Erro += 1;
    } else {
      //setDadosInvalid(false);
    }


    if (!validaCNPJ(formData['CNPJ'])) {
      errors['CNPJ'] = true;
      //setDadosInvalid(true);
      Erro += 1;
    } else {
      //setDadosInvalid(false);
    }


    if (!validaTelefone(formData['Telefone_Empresa'])) {
      errors['Telefone_Empresa'] = true;
      //setDadosInvalid(true);
      Erro += 1;
    } else {
      errors['Telefone_Empresa'] = false;

      //setDadosInvalid(false);
    }


    if (Localizacao['bairro'] == ''){
      Erro += 1;
      errors['bairro'] = true;

      //setDadosInvalid(true);
    }else{
      //setDadosInvalid(false);
    }


    if(isCepInvalid){
      Erro += 1;
      errors['CEP'] = true;

      //setDadosInvalid(true);
    }else{
      //setDadosInvalid(false);
    }

      const requiredFields = [
        'Nome_Empresa',
        'Email_Empresa',
        'Telefone_Empresa',
        'CEP_Empresa',
        'Numero',
        'Representante_Empresa',
        'CPF_Representante',
        'CNPJ'
      ];

    const invalidFields = requiredFields.filter(field => !formData[field] || formData[field].trim() === '');
    const isEveryFieldValid = invalidFields.length === 0;


    invalidFields.forEach(field => {
      errors[field] = true;
    });

    if (!isEveryFieldValid) {
      Erro += 1;
      //setDadosInvalid(true);
    }else{
      //setDadosInvalid(false);
    }

    const requiredFields2 = [
      'uf'
    ];

    const invalidFields2 = requiredFields2.filter(field => !Localizacao[field] || Localizacao[field].trim() === '');
    const isEveryFieldValid2 = invalidFields2.length === 0;

    
    invalidFields.forEach(field => {
      errors[field] = true;
    });

    if (!isEveryFieldValid2) {
      Erro += 1;
      //(true);
    //}else{
      //setDadosInvalid(false);
    }


    if (formData['CEP_Empresa'].includes('_') || formData['CEP_Empresa'] === "") {
      errors['CEP_Empresa'] = true;
      //setDadosInvalid(true);

      setLocalizacao({
        bairro: '',
        cep: '',
        complemento: '',
        ddd: '',
        gia: '',
        ibge: '',
        localidade: '',
        logradouro: '',
        siafi: '',
        uf: ''
      });

      Erro += 1;
    }else{
      errors['CEP_Empresa'] = false;

    }

    

    if (Erro != 0){

      toggle_C2();
      return;

    }else{

      const combinedData = {
        ...formData,
        ...Localizacao
        };

      axios.post('https://linkestagio.com.br/App.php?operation=editar-dados-empresa', combinedData)
        .then((response) => {
          const EmpresaInfo = JSON.parse(sessionStorage.getItem('EmpresaInfo'));
          const userData = formData;
          sessionStorage.removeItem('EmpresaInfo');
          sessionStorage.setItem('EmpresaInfo', JSON.stringify(userData)); // Armazena userData no localStorage          
          toggle_C3();
        })
        .catch((error) => {
          console.error('Erro de login:', error);
        });
    }
  };


  return (
    <>
        <Modal show={modal_C2} toggle={toggle_C2} className="modal-form">
          <ModalHeader style={{ borderBottom: 'none' }} toggle={toggle_C2}>

          </ModalHeader>
            <div className="d-flex flex-column align-items-center">
              <FaExclamationCircle size={96} color="#d24e42" />
            </div>

            <ModalBody>
            <br></br>
            <h1 className="form-title">Corrija todos os campos antes de continuar</h1>

            <p className="form-description">Alguns campos estão incorretos, corrija-os antes de editar os dados de sua empresa.</p>
            <Button className="login-button" variant="custom" onClick={toggle_C2}>Voltar para a página da empresa</Button>
            </ModalBody>
        </Modal>
        <Modal show={modal_S} toggle={toggle_S} className="modal-form">
          <ModalHeader style={{ borderBottom: 'none' }} toggle={toggle_S}>
          <h1 className="form-title">Excluir sua conta</h1>
          <p className="form-description" style={{ marginBottom: 0 , marginTop: 0 }}>Para excluir sua conta digite e confirme a sua senha atual.</p>
          </ModalHeader>
          <ModalBody>

          <Label for="email" className="linkLabel">Senha</Label>
          <div className="input-block">
            <Input
              name="senha"
              id="senha"
              placeholder="Senha"
              type={isPasswordVisible ? 'text' : 'password'}
              value={Senhas_Del.Senha_Del  || ''}
              onChange={(e) => setSenhas_Del({ ...Senhas_Del, Senha_Del: e.target.value })}
              required
            />
                        <button
              onClick={togglePasswordVisibility}
              style={{
                position: 'absolute',
                right: '25px',
                top: '27%',
                transform: 'translateY(-50%)',
                border: 'none',
                background: 'transparent',
                cursor: 'pointer'
              }}
              type="button"
            >
              {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
            </button>

          </div>
          <Label for="email" className="linkLabel">Confirmar Senha</Label>
          <div className="input-block">
            <Input
              name="senha"
              id="senha"
              placeholder="Senha"
              type={isConfirmPasswordVisible ? 'text' : 'password'}
              value={Senhas_Del.Senha_Del2  || ''}
              onChange={(e) => setSenhas_Del({ ...Senhas_Del, Senha_Del2: e.target.value })}
              required
            />
            <button
              onClick={toggleConfirmPasswordVisibility}
              style={{
                position: 'absolute',
                right: '25px',
                top: '60%',
                transform: 'translateY(-50%)',
                border: 'none',
                background: 'transparent',
                cursor: 'pointer'
              }}
              type="button"
            >
              {isConfirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <p className="form-description" style={{ marginBottom: 0 , marginTop: 0 }}><strong>Atenção:</strong> Essa ação não pode ser desfeita.</p>
          <Button className="login-button" onClick={Apagar_Conta} variant="" >Excluir a Conta</Button>

          </ModalBody>

        </Modal>


      <Modal show={modal_C} toggle={toggle_C} onHide={toggle_C} centered>
        <ModalHeader style={{ borderBottom: 'none' }} toggle={toggle_C}>

        </ModalHeader>
          <Checkmark size='64px' color='#2C7865' />

          <ModalBody>
          <br></br>
          <h1 className="form-title">Senha alterada com sucesso!</h1>
          <p className="form-description">Sua senha foi alterada com sucesso, utilize ela para realizar o login na plataforma Link Estágio.</p>
          <Button className="login-button" variant="custom" onClick={toggle_C}>Voltar para a página da Empresa.</Button>
          </ModalBody>
      </Modal>


      <Modal show={modal_C3} toggle={toggle_C3} onHide={toggle_C3} centered>
        <ModalHeader style={{ borderBottom: 'none' }} toggle={toggle_C3}>

        </ModalHeader>
          <Checkmark size='64px' color='#2C7865' />

          <ModalBody>
          <br></br>
          <h1 className="form-title">Dados alterados com sucesso!</h1>
          <p className="form-description">Seus dados cadastrais e curriculo foram atualizados com sucesso!</p>
          <Button className="login-button" variant="custom" onClick={toggle_C3}>Voltar para a página da Empresa.</Button>
          </ModalBody>
      </Modal>

      <div className={`${style.configAccount}`}>
        <nav className={`${style.configAccountNav}`}>
        <p
          className={`${style.optionNav} ${
            toggleState === 1 ? `${style.activateTabs} ${style.tabs}` : style.tabs
          }`}
          onClick={() => toggleTab(1)}
        >
          Minha Empresa
        </p>
        <p
          className={`${style.optionNav} ${
            toggleState === 2 ? `${style.activateTabs} ${style.tabs}` : style.tabs
          }`}
          onClick={() => toggleTab(2)}
        >
          Representante da Empresa
        </p>
        <p
          className={`${style.optionNav} ${
            toggleState === 3 ? `${style.activateTabs} ${style.tabs}` : style.tabs
          }`}
          onClick={() => toggleTab(3)}
        >
          Sede da Empresa
        </p>
        <p
          className={`${style.optionNav} ${
            toggleState === 4 ? `${style.activateTabs} ${style.tabs}` : style.tabs
          }`}
          onClick={() => toggleTab(4)}
        >
          Segurança
        </p>
        </nav>

        <Form className={`${style.bodyForm}`}>
          {toggleState === 1 && (
            <>
              <FormGroup>
                <Label  style={{ fontSize: '20px', color: '#7e2553' }} for="Nome_Empresa">Nome da Empresa</Label>
                <Input type="text"  invalid={errors.Nome_Empresa}
                 name="Nome_Empresa" id="Nome_Empresa" placeholder="Nome da Empresa" value={formData.Nome_Empresa} onChange={handleChange}/>
              </FormGroup>
              <FormGroup>
                <Label  style={{ fontSize: '20px', color: '#7e2553' }} for="CNPJ">CNPJ da Empresa</Label>
                <InputMask
                  mask="99.999.999/9999-99"
                  className={`form-control ${errors.CNPJ ? 'is-invalid' : ''}`}
                  name="CNPJ"
                  id="CNPJ"
                  placeholder="CNPJ da Empresa"
                  value={formData.CNPJ}
                  onChange={handleChange}
                  >
                  {(inputProps) => (
                      <input {...inputProps} />
                  )}
                </InputMask> 
              </FormGroup>
              <FormGroup>
                <Label  style={{ fontSize: '20px', color: '#7e2553' }} for="Email_Empresa">E-mail para contato</Label>
                <Input type="text" invalid={errors.Email_Empresa}
                 name="Email_Empresa" id="Email_Empresa" placeholder="Email para contato"  value={formData.Email_Empresa} onChange={handleChange}/>
              </FormGroup>
              <FormGroup>
                <Label  style={{ fontSize: '20px', color: '#7e2553' }} for="Telefone_Empresa">Telefone para contato</Label>
                <InputMask
                  mask="(99) 999999999"
                  maskChar={null}
                  value={formData.Telefone_Empresa || ''}
                  onChange={handleChange}
                  className={`form-control ${errors.Telefone_Empresa ? 'is-invalid' : ''}`}
                  id="Telefone_Empresa"
                  name="Telefone_Empresa"
                  placeholder="Insira o telefone"
                  type="text"
                  required
                >
                  {(inputProps) => (
                    <input {...inputProps} />
                  )}
                </InputMask>
              </FormGroup>
              {isDadosInvalid && (
              <div style={{ color: 'red' }}>
                  Dados inválidos em alguma das abas, verifique,preencha e corrija todos os dados.
              </div>
            )}
              <button  onClick={ProximaPagina} class={style.Confirm} >Próxima Página</button>

            </>
          )}

          {toggleState === 2 && (
            <>
              <FormGroup>
                <Label  style={{ fontSize: '20px', color: '#7e2553' }} for="Representante_Empresa">Representante da Empresa</Label>
                <Input type="text"  invalid={errors.Representante_Empresa}
                 name = "Representante_Empresa" id="Representante_Empresa" placeholder="Representante da Empresa" value={formData.Representante_Empresa} onChange={handleChange}/>
              </FormGroup>
              <FormGroup>
                <Label  style={{ fontSize: '20px', color: '#7e2553' }} for="CPF_Representante">CPF do Representante</Label>
                <InputMask
                        mask="999.999.999-99"
                        className={`form-control ${errors.CPF_Representante ? 'is-invalid' : ''}`}
                        name="CPF_Representante"
                        id="CPF_Representante"
                        placeholder="CPF do Representante"
                        value={formData.CPF_Representante}
                        onChange={handleChange}
                    >
                        {(inputProps) => (
                            <input {...inputProps} required />
                        )} 
                    </InputMask>
                    {isCpfInvalid && (
                        <div style={{ color: 'red' }}>
                            CPF inválido.
                        </div>
                    )}
              </FormGroup>

              <button onClick={ProximaPagina} class={style.Confirm} >Próxima Página</button>

            </>
          )}

          {toggleState === 3 && (
            <>
              <FormGroup>
                <Label style={{ fontSize: '20px', color: '#7e2553' }} for="CEP_Empresa">CEP da Sede</Label>
                <InputMask
                      mask="99999-999"
                      value={formData.CEP_Empresa || ''}
                      onChange={handleChange}
                      className={`form-control ${errors.CEP_Empresa ? 'is-invalid' : ''}`}
                      id="CEP_Empresa"
                      name="CEP_Empresa"
                      placeholder="Insira o CEP da Sede"
                      type="text"
                      required
                    >
                  {(inputProps) => (
                    <input {...inputProps} />
                  )}
                  </InputMask>
              </FormGroup>

              <div className="input-row">
                <div className="input-block half">

                  <FormGroup>
                    <Label style={{ fontSize: '20px', color: '#7e2553' }} for="uf">Estado</Label>
                    <Input type="text"
                     id="uf" name = "uf" placeholder="" value={Localizacao.uf} disabled/>
                  </FormGroup>

                </div>
                <div className="input-block half">

                  <FormGroup>
                    <Label style={{ fontSize: '20px', color: '#7e2553' }} for="bairro">Bairro</Label>
                    <Input type="text" id="bairro" name = "bairro" placeholder="" value={Localizacao.bairro}disabled/>
                  </FormGroup>

                </div>
              </div>

              <div className="input-row">
                <div className="input-block half">
                <FormGroup>
                  <Label style={{ fontSize: '20px', color: '#7e2553' }} for="localidade">Cidade</Label>
                  <Input type="text" id="localidade" name = "localidade"  placeholder="" value={Localizacao.localidade} disabled/>
                </FormGroup>
                </div>
                <div className="input-block half">

                  <FormGroup>
                    <Label style={{ fontSize: '20px', color: '#7e2553' }} for="logradouro">Rua</Label>
                    <Input type="text" id="logradouro" name = "logradouro" placeholder="" value={Localizacao.logradouro} disabled/>
                  </FormGroup>
                </div>
              </div>

              <div className="input-row">
                <div className="input-block half">
              <FormGroup>
                <Label style={{ fontSize: '20px', color: '#7e2553' }} for="Numero">Número</Label>
                <Input type="text" invalid={errors.Numero} id="Numero" name="Numero" placeholder="Número da Sede" value={formData.Numero} onChange={handleChange}/>
              </FormGroup>
              </div>
              <div className="input-block half">

                <FormGroup>
                  <Label style={{ fontSize: '20px', color: '#7e2553' }} for="Numero">Complemento</Label>
                  <Input type="text" id="Complemento_Empresa" name="Complemento_Empresa" placeholder="Complemento" value={formData.Complemento_Empresa} onChange={handleChange}/>
                </FormGroup>
              </div>
              </div>
              {isDadosInvalid && (
              <div style={{ color: 'red' }}>
                  Dados inválidos em alguma das abas, verifique,preencha e corrija todos os dados.
              </div>
              )}
              <button onClick={handleSubmit} class={style.Confirm} >Confirmar</button>

            </>
          )}

          {toggleState === 4 && (
            <>
              <FormGroup>

                <Label style={{ fontSize: '20px', color: '#7e2553' }} for="Senhas">Senha Atual</Label>
                <div className="input-block" style={{ position: 'relative' }}>

                <Input                           
                type={isPasswordVisible1 ? 'text' : 'password'}
                name="Senha_atual" id="Senha_atual" placeholder="" 
                value={Senhas.Senha_atual  || ''} onChange={handleSenhaChange}/>
                <button
                    onClick={togglePasswordVisibility1}
                    style={{
                      position: 'absolute',
                      right: '15px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      border: 'none',
                      background: 'transparent',
                      cursor: 'pointer'
                    }}
                    type="button"
                  >
                    {isPasswordVisible1 ? <FaEyeSlash /> : <FaEye />}
                  </button>
              </div>
              </FormGroup>
              <FormGroup>
                <Label style={{ fontSize: '20px', color: '#7e2553' }} for="Senha_nova">Nova Senha</Label>
                <div className="input-block" style={{ position: 'relative' }}>

                <Input type={isPasswordVisible2 ? 'text' : 'password'} name="Senha_nova" id="Senha_nova" placeholder="" 
                value={Senhas.Senha_nova  || ''} onChange={handleSenhaChange}/>
                <button
                    onClick={togglePasswordVisibility2}
                    style={{
                      position: 'absolute',
                      right: '15px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      border: 'none',
                      background: 'transparent',
                      cursor: 'pointer'
                    }}
                    type="button"
                  >
                    {isPasswordVisible2 ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
              
              </FormGroup>
              <FormGroup>
                <Label style={{ fontSize: '20px', color: '#7e2553' }} for="Senha_c_nova">Confirmar senha</Label>
                <div className="input-block" style={{ position: 'relative' }}>

                <Input  type={isPasswordVisible3 ? 'text' : 'password'} name="Senha_c_nova" id="Senha_c_nova" placeholder="" 
                value={Senhas.Senha_c_nova  || ''} onChange={handleSenhaChange}/>
              
              
                <button
                    onClick={togglePasswordVisibility3}
                    style={{
                      position: 'absolute',
                      right: '15px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      border: 'none',
                      background: 'transparent',
                      cursor: 'pointer'
                    }}
                    type="button"
                  >
                    {isPasswordVisible3 ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
              </FormGroup>
              {isPasswordInvalid && (
                  <div style={{ color: 'red'}}>
                      Senhas Diferentes ou Invalidas.
                  </div>
              )}

              <div class={style.container_separar}>
                <button onClick={Alterar_senha} class={style.Confirm} >Alterar Senha</button>
                <button onClick={toggle_S} class={style.Delete} >Excluir Conta</button>
              </div>
            </>
          )}
        </Form>
      </div>
    </>
  );
};

export default UserConfig;